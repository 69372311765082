exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-entrar-js": () => import("./../../../src/pages/entrar.js" /* webpackChunkName: "component---src-pages-entrar-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-por-que-grafiame-js": () => import("./../../../src/pages/por-que-grafiame.js" /* webpackChunkName: "component---src-pages-por-que-grafiame-js" */),
  "component---src-pages-privacidade-js": () => import("./../../../src/pages/privacidade.js" /* webpackChunkName: "component---src-pages-privacidade-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */),
  "component---src-pages-solucoes-js": () => import("./../../../src/pages/solucoes.js" /* webpackChunkName: "component---src-pages-solucoes-js" */),
  "component---src-pages-termo-js": () => import("./../../../src/pages/termo.js" /* webpackChunkName: "component---src-pages-termo-js" */)
}

